import { Routes } from '@angular/router';
import { DefaultLayoutComponent } from './layout';
import { DashboardResolver } from './resolvers/dashboard.resolver';

// add this to some section to trigger the resolver and the closure of the splash screen
// import { DashboardResolver } from './resolvers/dashboard.resolver';
// resolve: {'itemsList': DashboardResolver},

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'tracker',
    pathMatch: 'full',
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/routes').then((m) => m.routes),
      },
      {
        path: 'tracker',
        loadChildren: () => import('./views/dashboard/routes').then((m) => m.routes),
      },
      {
        path: 'market',
        loadChildren: () => import('./views/market/routes').then((m) => m.routes)
      },
      {
        path: 'market/:code',
        loadChildren: () => import('./views/market/routes').then((m) => m.routes)
      },
      {
        path: 'contracts',
        loadChildren: () => import('./views/contracts/routes').then((m) => m.routes)
      },
      {
        path: 'posts',
        loadChildren: () => import('./views/trades/routes').then((m) => m.routes)
      },
      {
        path: 'profile',
        loadComponent: () => import('./views/profiles/profile/profile.component').then(m => m.ProfileComponent),
        data: {
          title: 'Profile'
        }
      },
      {
        path: 'rulebook',
        loadChildren: () => import('./views/rulebook/routes').then((m) => m.routes)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/routes').then((m) => m.routes)
      },
    ]
  },
  {
    path: '404',
    loadComponent: () => import('./views/pages/page404/page404.component').then(m => m.Page404Component),
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    loadComponent: () => import('./views/pages/page500/page500.component').then(m => m.Page500Component),
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    loadComponent: () => import('./views/login/login.component').then(m => m.LoginComponent),
    data: {
      title: 'Login'
    }
  },
  {
    path: 'login/*',
    loadComponent: () => import('./views/login/login.component').then(m => m.LoginComponent),
    data: {
      title: 'Login'
    }
  },
  {
    path: 'logout',
    loadComponent: () => import('./views/logout/logout.component').then(m => m.LogoutComponent),
    data: {
      title: 'Logout'
    }
  },
  { path: '**', redirectTo: 'dashboard' }
];
